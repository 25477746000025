<template>
  <th-page-wrapper v-loading="loading">
    <empty v-if="!loading && isEmpty" />
    <th-datatable
      v-else-if="!loading && !isEmpty"
      ref="table"
      do-route
      no-meta-check
      do-route-filters
      show-search-filter
      route-base="/loyalty/abocard_systems"
      resource="abocardSystems"
      :headers="headers"
      :show-operations="false"
      :buttons="computedButtons"
      :resource-query="resourceQuery"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import Empty from './empty'

export default {
  name: 'AbocardSystemsAll',
  metaInfo() {
    return {
      title: this.$t('nav.main.items.loyalty.abocard_systems')
    }
  },
  components: {
    Empty
  },
  data() {
    return {
      isEmpty: true,
      loading: false,
      headers: [
        {
          field: 'name',
          label: this.$t('pages.abocard_systems.all.table.name'),
          minWidth: 140,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'total_value',
          label: this.$t('pages.abocard_systems.all.table.total_value'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row, column) => {
            if (!Number.isFinite(row?.total_value)) return '-'
            return this.$formatCurrency(row.total_value, this.defaultCurrency)
          }
        },
        {
          field: 'selling_price',
          label: this.$t('pages.abocard_systems.all.table.selling_price'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row, column) => {
            if (!Number.isFinite(row?.selling_price)) return '-'
            return this.$formatCurrency(row.selling_price, this.defaultCurrency)
          }
        },
        {
          field: 'units',
          label: this.$t('pages.abocard_systems.all.table.units'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        }
      ],
      resourceQuery: { query: { deleted: false } },
      buttons: [
        {
          type: 'create',
          scopes: ['loyalty:abocard_systems:create'],
          clickHandler: () => this.$router.push({ name: 'abocard-systems-new' })
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      defaultCurrency: 'Config/getCurrentDefaultCurrency'
    }),
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      try {
        this.loading = true
        const { abocardSystems } = await this.$resourceFetch({
          resource: 'abocardSystems',
          query: { active: undefined } //setting active to undefined will remove the default value from API query.
        })
        this.isEmpty = Array.isArray(abocardSystems) && !abocardSystems.length
      } catch (err) {
        this.handleLoadingError(err)
      } finally {
        this.loading = false
      }
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('nav.main.items.loyalty.abocard_systems')
        })
      })
    }
  }
}
</script>
