<template>
  <div class="flex flex-col h-full p-6">
    <div class="flex justify-end pb-6 flex-shrink-0">
      <el-button
        v-permissions="['loyalty:abocard_systems:create']"
        type="primary"
        class="flex items-center h-th-action-button-height"
        @click="$router.push({ name: 'abocard-systems-new' })"
      >
        <svgicon
          :src="require('@/assets/icons/th-icon-plus.svg')"
          :style="{ height: '20px', width: '20px' }"
          class="mr-1 fill-current"
        />
        {{ $t('common.components.th_datatable.addNew') }}
      </el-button>
    </div>

    <base-card class="flex-grow p-12 flex flex-col">
      <div
        class="leading-relaxed text-md max-w-lg flex-grow"
        :class="{ italic: !isWhiteLabel }"
      >
        <div class="font-bold text-lg mb-2">
          {{ $t('pages.abocard_systems.empty.text.title') }}
        </div>
        <div>{{ $t('pages.abocard_systems.empty.text.sub') }}</div>
      </div>

      <div class="flex justify-end items-end">
        <img
          :src="productsListEmpty"
          alt="abocard-list-empty"
          style="max-height: 95%"
        />
      </div>
    </base-card>
  </div>
</template>

<script>
import productsListEmpty from '@/assets/illustrations/products-list-empty.svg'
import BaseCard from '@/components/base-card'
import { isUnifiedCommerce } from '@/constants'

export default {
  components: {
    BaseCard
  },
  data() {
    return {
      productsListEmpty
    }
  },
  computed: {
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  }
}
</script>
